<template>
  <InputBlock
    v-if="property && isVisible"
    :prop="`properties.${props.field.propertyId}`"
    :label="property[props.lang]?.name"
    :tooltip-text="props.field[props.lang]?.description"
    :label-truncate="false"
    show-asterisk
  >
    <TextInput
      v-if="property.type === 'text'"
      v-model="form.properties[props.field.propertyId]"
    />
    <template v-if="property.type === 'select' || property.type === 'multiSelect'">
      <template v-if="options?.length <= 5">
        <RadioGroup
          v-if="property.type === 'select'"
          v-model="form.properties[property.id]"
          :options="options"
        />
        <CheckboxGroup
          v-else-if="property.type === 'multiSelect'"
          v-model="form.properties[property.id]"
          :options="options"
          :max="props.field.maxSelectable || Infinity"
        />
      </template>
      <Select
        v-else
        v-model="form.properties[props.field.propertyId]"
        :options="options"
        :multiple="property.type === 'multiSelect'"
        :multiple-limit="props.field.maxSelectable || 0"
        :placeholder="props.section.$t('abc425')"
      />
    </template>
  </InputBlock>
</template>

<script setup>
const props = defineProps({
  field: {
    type: Object,
    default: () => ({}),
  },
  stats: {
    type: Object,
    default: () => ({}),
  },
  lang: {
    type: String,
    default: '',
  },
  form: {
    type: Object,
    default: () => ({}),
  },
  event: {
    type: Object,
    default: () => ({}),
  },
  section: {
    type: Object,
    default: () => ({}),
  },
  partners: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['update:form']);

// Refs
const form = useVModel(props, 'form', emit);

// Computed
const property = computed(() => props.event?.guestlist?.properties?.find((item) => item.id === props.field.propertyId));
const isVisible = computed(() => {
  if (props.field.visibility === 'visible') {
    return true;
  }

  if (props.field.visibility === 'condition') {
    return (props.field.visibleOnlyTo || []).some((property) => {
      const [propertyId, value] = property.split('/');
      if (Array.isArray(value)) {
        return value.includes(props.form.properties?.[propertyId]);
      } else {
        return props.form.properties?.[propertyId]?.includes(value);
      }
    });
  }

  return false;
});
const options = computed(() => (property.value?.options || []).map((option) => {
  const stat = props.stats?.guestPropertyStats?.[`${props.field.propertyId}/${option.id}`];
  const futureStat = stat + Number(props.partners || 0); // registered guests in option + partners
  const maxCapacity = props.field?.optionsSettings?.[option.id]?.maxCapacity;
  const maxCapacityReached = Boolean(maxCapacity && futureStat >= maxCapacity);

  return {
    id: option.id,
    text: option[props.lang]?.value,
    tooltipText: maxCapacityReached ? useT('xyz971') : undefined,
    isDisabled: maxCapacityReached,
  };
}));
const disabledSelectedOptionsIds = computed(() => options.value
  ?.filter((option) => (props.form.properties?.[props.field.propertyId] || '')?.includes(option.id) && option.isDisabled)
  .map((option) => option.id));

// Watchers
watch(isVisible, () => {
  if (!isVisible.value) {
    _set(form.value, `properties.${props.field.propertyId}`, property?.value?.type === 'multiSelect' ? [] : '');
  }
});

watch(disabledSelectedOptionsIds, () => {
  if (disabledSelectedOptionsIds.value?.length) {
    if (property?.value?.type === 'multiSelect') {
      const hasSomeDisabledOptions = (form.value.properties?.[props.field.propertyId] || []).some((item) => disabledSelectedOptionsIds.value?.includes(item));
      if (hasSomeDisabledOptions) {
        _set(form.value, `properties.${props.field.propertyId}`, (form.value.properties?.[props.field.propertyId] || [])?.filter((item) => !disabledSelectedOptionsIds.value?.includes(item)));
      }
    } else if (disabledSelectedOptionsIds.value?.includes(form.value.properties?.[props.field.propertyId])) {
      _set(form.value, `properties.${props.field.propertyId}`, '');
    }
  }
});
</script>
